export const moduleName = 'user'

export const USER_LOAD = `${moduleName}/USER_LOAD`
export const USER_SUCCESS = `${moduleName}/USER_SUCCESS`
export const USER_FAIL = `${moduleName}/USER_FAIL`

export const BALANCE_LOAD = `${moduleName}/BALANCE_LOAD`
export const BALANCE_SUCCESS = `${moduleName}/BALANCE_SUCCESS`
export const BALANCE_FAIL = `${moduleName}/BALANCE_FAIL`

export const CREATE_LOAD = `${moduleName}/CREATE_LOAD`
export const CREATE_SUCCESS = `${moduleName}/CREATE_SUCCESS`
export const CREATE_FAIL = `${moduleName}/CREATE_FAIL`

export const UPDATE_WALLET_LOAD = `${moduleName}/UPDATE_WALLET_LOAD`
export const UPDATE_WALLET_SUCCESS = `${moduleName}/UPDATE_WALLET_SUCCESS`
export const UPDATE_WALLET_FAIL = `${moduleName}/UPDATE_WALLET_FAIL`

export const SET_PROFILE_LOAD = `${moduleName}/SET_PROFILE_LOAD`
export const SET_PROFILE_SUCCESS = `${moduleName}/SET_PROFILE_SUCCESS`
export const SET_PROFILE_FAIL = `${moduleName}/SET_PROFILE_FAIL`

export const SET_NAME_LOAD = `${moduleName}/SET_NAME_LOAD`
export const SET_NAME_SUCCESS = `${moduleName}/SET_NAME_SUCCESS`
export const SET_NAME_FAIL = `${moduleName}/SET_NAME_FAIL`
