import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { ContentLoader, Page } from 'ui'
import { isOpenedSelector, name } from 'models/window/selectors'
import { useSelector } from 'react-redux'
import { Invite } from '../invite'
import { modalNames } from '../modal/constants/names'

export const Layout = () => {
    const isOpened = useSelector(isOpenedSelector)
    const windowName = useSelector(name)

    useEffect(() => {
        if (isOpened) {
            document.body.classList.add('blurred')
        } else {
            document.body.classList.remove('blurred')
        }

        if (
            isOpened &&
            (windowName === modalNames.FAQ ||
                windowName === modalNames.WITHDRAW ||
                windowName === modalNames.BALANCE)
        ) {
            document.body.classList.add('eggcover')
        } else {
            document.body.classList.remove('eggcover')
        }

        return () => {
            document.body.classList.remove('blurred')
        }
    }, [isOpened, windowName])

    return (
        <>
            <Page>
                <Suspense fallback={<ContentLoader />}>
                    <Outlet />
                </Suspense>
                <Invite />
            </Page>
        </>
    )
}
