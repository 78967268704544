import { List, Record } from 'immutable'
import { FAIL, LOAD, SUCCESS } from './constants'

const FriendsState = Record({
    referral_id: null,
    referral_date: null,
    referral_eggs: null,
    referral_name: null,
    referral_profile: null,
})

const FriendsRecord = Record({
    isLoading: false,
    friends: List(),
})

const reducer = (state = new FriendsRecord(), action) => {
    const { type, payload } = action

    switch (type) {
        case LOAD:
            return state.set('isLoading', true)

        case SUCCESS:
            return state
                .set(
                    'friends',
                    List(payload.map((friend) => new FriendsState(friend)))
                )
                .set('isLoading', false)

        case FAIL:
            return state.set('isLoading', false)

        default:
            return state
    }
}

export default reducer
