import { Counter } from 'features/friends/counter'
import { Table } from 'features/friends/table'
import { Empty } from 'features/friends/empty'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getFriends } from 'models/friends'
import { userSelector } from 'models/user/selectors'
import { friendsSelector } from 'models/friends/selectors'

import c from './assets/sass/page.module.scss'

const FriendsPage = () => {
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const friends = useSelector(friendsSelector)
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }

    useEffect(() => {
        dispatch(getFriends(params, user.telegram_id))
    }, [dispatch, user.telegram_id])

    return (
        <div className={c.component}>
            <Counter amount={user.referral_count} bonuses={user.earned_eggs} />
            <div className={c.middle}>
                {user.referral_count > 0 ? (
                    <Table friends={friends} />
                ) : (
                    <Empty />
                )}
            </div>
        </div>
    )
}

export default FriendsPage
