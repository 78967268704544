import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isLoadingSelector } from 'models/friends/selectors'
import { Item } from '../item'
import { ItemSkeleton } from '../item-skeleton'

import c from './assets/sass/table.module.scss'

export const Table = ({ friends }) => {
    const [maxRefHeight, setMaxRefHeight] = useState(0)
    const isLoading = useSelector(isLoadingSelector)

    useEffect(() => {
        const height = window.innerHeight - 250
        setMaxRefHeight(height)

        const handleResize = () => {
            const height = window.innerHeight - 250
            setMaxRefHeight(height)
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const renderContent = () => {
        if (isLoading) {
            return Array.from({ length: 30 }).map((_, index) => (
                <ItemSkeleton key={index} />
            ))
        }

        return friends.map((friend) => (
            <Item key={friend.referral_id} friend={friend} />
        ))
    }

    return (
        <div className={c.component}>
            <div
                className={c.wrapper}
                style={{ maxHeight: `${maxRefHeight}px` }}
            >
                <ul className={c.list}>{renderContent()}</ul>
            </div>
        </div>
    )
}
