import Skeleton from 'react-loading-skeleton'

import c from './assets/sass/itemSkeleton.module.scss'

export const ItemSkeleton = () => {
    return (
        <li className={c.component}>
            <span className={c.name}>
                <Skeleton baseColor="#4F4A4A" />
            </span>
            <span className={c.eggs}>
                <Skeleton baseColor="#4F4A4A" />
            </span>
        </li>
    )
}
