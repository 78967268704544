const FAQ = 'FAQ'
const SETTINGS = 'SETTINGS'
const INVITE = 'INVITE'
const DISCONNECT = 'DISCONNECT'
const WITHDRAW = 'WITHDRAW'
const BALANCE = 'BALANCE'
const GEMS = 'GEMS'
const PROFILE = 'PROFILE'

export const modalNames = {
    FAQ,
    SETTINGS,
    INVITE,
    DISCONNECT,
    WITHDRAW,
    BALANCE,
    GEMS,
    PROFILE,
}
