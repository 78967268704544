import {CheckGems} from "features/gems/check-gems";

import c from './assets/sass/gems.module.scss'

const GemsPage = () => {
    return (
        <div className={c.component}>
            <div className={c.row}>
                <div className={c.top}>
                    <div className={c.avatar}></div>
                    <span className={c.title}>CSGOPOLYGON</span>
                    <p className={c.description}>Short profile description</p>
                    <button className={c.goButton}>
                        <span className={c.buttonTitle}>Go to Telegram</span>
                    </button>
                </div>
                <CheckGems/>
            </div>
        </div>
    )
}

export default GemsPage
