import { Record } from 'immutable'
import {
    BALANCE_FAIL,
    BALANCE_LOAD,
    BALANCE_SUCCESS,
    USER_FAIL,
    USER_LOAD,
    USER_SUCCESS,
    UPDATE_WALLET_LOAD,
    UPDATE_WALLET_SUCCESS,
    UPDATE_WALLET_FAIL,
    SET_PROFILE_LOAD,
    SET_PROFILE_SUCCESS,
    SET_PROFILE_FAIL,
    SET_NAME_LOAD,
    SET_NAME_SUCCESS,
    SET_NAME_FAIL,
} from './constants'

const UserState = Record({
    name: null,
    earned_eggs: null,
    telegram_id: null,
    invited_by: null,
    referrals: null,
    referral_count: null,
    profile: null,
    wallet_address: null,
    withdraw_status: null,
    available_eggs: null,
    created_at: null,
})

const UserRecord = Record({
    isLoadingUser: false,
    user: new UserState(),
    isBalanceLoading: false,
    balance: null,
    isUpdatingWallet: false,
    isUpdatingProfile: false,
    isUpdatingName: false,
})

const reducer = (state = new UserRecord(), action) => {
    const { type, payload } = action

    switch (type) {
        case USER_LOAD:
            return state.set('isLoadingUser', true)

        case USER_SUCCESS:
            return state
                .set('user', new UserState(payload))
                .set('isLoadingUser', false)

        case USER_FAIL:
            return state.set('isLoadingUser', false)

        case BALANCE_LOAD:
            return state.set('isBalanceLoading', true)

        case BALANCE_SUCCESS:
            return state.set('balance', payload).set('isBalanceLoading', false)

        case BALANCE_FAIL:
            return state.set('isBalanceLoading', false)

        case UPDATE_WALLET_LOAD:
            return state.set('isUpdatingWallet', true)

        case UPDATE_WALLET_SUCCESS:
            return state
                .set(
                    'user',
                    state.user.set('wallet_address', payload.wallet_address)
                )
                .set('isUpdatingWallet', false)

        case UPDATE_WALLET_FAIL:
            return state.set('isUpdatingWallet', false)

        case SET_PROFILE_LOAD:
            return state.set('isUpdatingProfile', true)

        case SET_PROFILE_SUCCESS:
            return state
                .set('user', state.user.set('profile', payload.profile))
                .set('isUpdatingProfile', false)

        case SET_PROFILE_FAIL:
            return state.set('isUpdatingProfile', false)

        case SET_NAME_LOAD:
            return state.set('isUpdatingName', true)

        case SET_NAME_SUCCESS:
            return state
                .set('user', state.user.set('name', payload.name))
                .set('isUpdatingName', false)

        case SET_NAME_FAIL:
            return state.set('isUpdatingName', false)

        default:
            return state
    }
}

export default reducer
