import { FAIL, LOAD, SUCCESS } from './constants'
import { userApi } from 'api/user'

export const getFriends = (initData, telegram_id) => (dispatch) => {
    dispatch({ type: LOAD })

    userApi
        .getFriends(initData, telegram_id)
        .then((response) => {
            dispatch({
                type: SUCCESS,
                payload: response.data.referrals,
            })
        })
        .catch(() => {
            dispatch({ type: FAIL })
        })
}
