import { createSelector } from 'reselect'
import { moduleName } from './constants'

const state = (state) => state[moduleName]

export const isLoadingSelector = createSelector(
    state,
    ({ isLoading }) => isLoading
)

export const addressesSelector = createSelector(
    state,
    ({ addresses }) => addresses
)
