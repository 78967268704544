import axios from 'axios'
import { toast } from 'react-toastify'

export const setLang = (lang) => {
    axios.defaults.headers.common['X-localization'] = lang
}

axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // if (error.response.status === 404) {
        //     toast('Not found', { closeButton: false, type: 'error' })
        // }

        return Promise.reject(error)
    }
)

export default axios
