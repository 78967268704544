import cn from 'classnames'
import {useDispatch} from "react-redux";
import { openWindow } from 'models/window/actions'
import { modalNames } from 'features/modal/constants/names'
import { Timer } from './timer'

import c from './assets/sass/checkGems.module.scss'

export const CheckGems = () => {
    const dispatch = useDispatch()
    const isDisabled = false

    const buttonHandler = () => {
        if (!isDisabled) {
            dispatch(openWindow(modalNames.GEMS))
        }
    }

    return (
        <div className={c.component}>
            <div className={c.row}>
                {isDisabled && (
                    <div className={c.timer}>
                        <Timer/>
                    </div>
                )}
                <button
                    className={cn(c.button, {
                        [c.disabled]: isDisabled,
                    })}
                    onClick={buttonHandler}
                >
                    <span className={c.title}>Check Gems</span>
                </button>
            </div>
        </div>
    )
}
