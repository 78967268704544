import { Record } from 'immutable'
import {
    SET_PROFILE_LINK,
} from './constants'

const GemsState = Record({
    data: null,
})

const reducer = (state = new GemsState(), action) => {
    const { type, payload } = action

    switch (type) {
        case SET_PROFILE_LINK:
            return state

        default:
            return state
    }
}

export default reducer
