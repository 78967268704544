import { Record, List } from 'immutable'
import { FAIL, LOAD, SUCCESS } from './constants'

const InviterRecord = Record({
    name: '',
    earned_eggs: 0,
    telegram_id: '',
    invited_by: null,
    referrals: List(),
    referral_count: 0,
    profile: '',
    wallet_address: '',
    withdraw_status: false,
    available_eggs: 0,
    created_at: '',
})

const HoldersRecord = Record({
    isLoading: false,
    inviters: List(),
    total: 0,
})

const reducer = (state = new HoldersRecord(), { type, payload }) => {
    switch (type) {
        case LOAD:
            return state.set('isLoading', true)

        case SUCCESS:
            const inviters = List(
                payload.map((inviter) => InviterRecord(inviter))
            )
            return state
                .set('inviters', inviters)
                .set('total', inviters.size)
                .set('isLoading', false)

        case FAIL:
            return state.set('isLoading', false)

        default:
            return state
    }
}

export default reducer
