import { Record } from 'immutable'
import { CLOSE_WINDOW, OPEN_WINDOW } from './constants'

const OpenedWindowRecord = Record({
    name: null,
    props: null,
    isOpened: false,
})

const reducer = (state = new OpenedWindowRecord(), action) => {
    const { type, payload } = action

    switch (type) {
        case OPEN_WINDOW:
            return state
                .set('name', payload.name)
                .set('props', payload.props)
                .set('isOpened', true)

        case CLOSE_WINDOW:
            return state.set('name', null).set('isOpened', false)

        default:
            return state
    }
}

export default reducer
