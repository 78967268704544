import cn from 'classnames'
import {useWindowActions} from 'models/window'

import { ReactComponent as CloseIcon } from './assets/images/close.svg'

import c from "./assets/sass/gemsModal.module.scss";

export const GemsModal = () => {
    const { closeWindow } = useWindowActions()
    const collectedEggs = 834
    const isWin = true

    const handleClickClose = () => {
        closeWindow()
    }

    const getSubtitle = () => {
        if (isWin) {
            return `You just collected <strong>${collectedEggs || 0 } EGGS</strong>`
        }

        return 'You will be lucky next time!'
    }

    return (
        <div className={cn(c.component, {
            [c.win]: isWin,
        })}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon/>
            </button>
            <div className={c.top}>
                <span className={c.title}>
                    {isWin ? 'Congratulations!' : 'Sorry'}
                </span>
                <p className={c.subtitle}>
                    {getSubtitle()}
                </p>
            </div>
            <button className={c.button} onClick={handleClickClose}>Thanks!</button>
        </div>
    )
}
