import { useState, useRef, useEffect } from 'react'
import { HoldersTable } from 'features/holders/holders-table'
import cn from 'classnames'
import { InvitersTable } from 'features/holders/inviters-table'

import { ReactComponent as HoldersIcon } from './assets/images/holders-icon.svg'
import { ReactComponent as InvitersIcon } from './assets/images/inviters-icon.svg'

import c from './assets/sass/page.module.scss'

const HoldersPage = () => {
    const [activeTab, setActiveTab] = useState(0)
    const indicatorRef = useRef(null)
    const tabsRef = useRef([])

    useEffect(() => {
        if (tabsRef.current[activeTab] && indicatorRef.current) {
            const tab = tabsRef.current[activeTab]
            const tabRect = tab.getBoundingClientRect()
            const tabsContainerRect = tab.parentNode.getBoundingClientRect()

            const newWidth = tabRect.width
            const newLeft = tabRect.left - tabsContainerRect.left

            indicatorRef.current.style.width = `${newWidth}px`
            indicatorRef.current.style.transform = `translateX(${newLeft}px)`
        }
    }, [activeTab])

    return (
        <div className={c.component}>
            <div className={c.top}>
                <div className={c.tabs}>
                    <div className={c.indicator} ref={indicatorRef} />
                    <div
                        className={cn(c.item, { [c.active]: activeTab === 0 })}
                        onClick={() => setActiveTab(0)}
                        ref={(el) => (tabsRef.current[0] = el)}
                    >
                        <button className={c.button}>
                            <HoldersIcon className={c.tabsIcon} />
                            <span className={c.tabsTitle}>Holders</span>
                        </button>
                    </div>
                    <div
                        className={cn(c.item, { [c.active]: activeTab === 1 })}
                        onClick={() => setActiveTab(1)}
                        ref={(el) => (tabsRef.current[1] = el)}
                    >
                        <button className={c.button}>
                            <InvitersIcon className={c.tabsIcon} />
                            <span className={c.tabsTitle}>Inviters</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className={c.middle}>
                {activeTab === 0 ? <HoldersTable /> : <InvitersTable />}
            </div>
        </div>
    )
}

export default HoldersPage
