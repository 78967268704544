import cn from 'classnames'
import { openWindow } from 'models/window/actions'
import { modalNames } from 'features/modal/constants/names'
import { useDispatch, useSelector } from 'react-redux'
import { isLoadingUserSelector, userSelector } from 'models/user/selectors'
import Skeleton from 'react-loading-skeleton'
import { routes } from 'core/routes/paths'
import { Link } from 'react-router-dom'

import { ReactComponent as FriendsIcon } from './assets/images/friends.svg'
import { ReactComponent as HoldersIcon } from './assets/images/holders.svg'
import { ReactComponent as BuyIcon } from './assets/images/buy.svg'
import { ReactComponent as SettingsIcon } from './assets/images/settings.svg'

import c from './assets/sass/navigation.module.scss'

export const Navigation = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector(isLoadingUserSelector)
    const user = useSelector(userSelector)

    const openSettings = () => {
        dispatch(openWindow(modalNames.SETTINGS))
    }

    return (
        <nav className={c.component}>
            <ul className={c.list}>
                <li className={c.item}>
                    <Link
                        to={routes.friends}
                        className={cn(c.button, c.friends, c.active)}
                    >
                        <FriendsIcon className={c.icon} />
                        <div className={c.text}>
                            <span className={c.counter}>
                                {isLoading ? (
                                    <Skeleton />
                                ) : (
                                    user.referral_count || 0
                                )}
                            </span>
                            <span className={c.subtitle}>Friends</span>
                        </div>
                    </Link>
                </li>
                <li className={c.item}>
                    <Link to={routes.holders} className={cn(c.button, c.new)}>
                        <HoldersIcon className={c.icon} />
                        <span className={c.title}>Holders</span>
                    </Link>
                </li>
                <li className={c.item}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://dedust.io/swap/TON/EQBnjc9l5PJ0HnU6auEsqpo9N2WMnifSuFMCbAKipTbzKt48"
                        className={c.button}
                    >
                        <BuyIcon className={c.icon} />
                        <span className={c.title}>Buy</span>
                    </a>
                </li>
                <li className={c.item}>
                    <button className={c.button} onClick={openSettings}>
                        <SettingsIcon className={c.icon} />
                        <span className={c.title}>Settings</span>
                    </button>
                </li>
            </ul>
        </nav>
    )
}
