import cn from 'classnames'
import { useSelector } from 'react-redux'
import { name } from 'models/window/selectors'
import { modalNames } from 'features/modal/constants/names'
import { useEffect, useState } from 'react'

import c from './assets/sass/egg.module.scss'

export const Egg = ({ type }) => {
    const windowName = useSelector(name)
    const [isTapped, setIsTapped] = useState(false)

    useEffect(() => {
        let timer = null

        if (isTapped) {
            timer = setTimeout(() => {
                setIsTapped(false)
            }, 100)
        }

        return () => clearTimeout(timer)
    }, [isTapped])

    const tapHandler = () => {
        setIsTapped(true)
    }

    return (
        <div
            className={cn(c.component, {
                [c.main]: type === 'main',
                [c.invite]: windowName === modalNames.INVITE,
                [c.tapped]: isTapped,
            })}
            onClick={tapHandler}
        >
            <div className={c.platform}>
                <div className={c.light} />
            </div>
            <div className={c.eggRow}>
                <div className={c.egg}>
                    <div className={c.eyes} />
                    <div className={c.cloud}>
                        <span>LFG</span>
                    </div>
                    <div className={c.rocketRow}>
                        <div className={c.rocket} />
                    </div>
                </div>
            </div>
        </div>
    )
}
