import { FAIL, LOAD, SUCCESS } from './constants'
import { mcapApi } from 'api/mcap'

export const getMcap = () => (dispatch) => {
    dispatch({ type: LOAD })

    mcapApi
        .getMcap()
        .then((response) => {
            const priceUsd =
                response.data.rates[
                    '0:678dcf65e4f2741e753a6ae12caa9a3d37658c9e27d2b853026c02a2a536f32a'
                ].prices.USD
            dispatch({
                type: SUCCESS,
                payload: priceUsd,
            })
        })
        .catch(() => {
            dispatch({ type: FAIL })
        })
}
