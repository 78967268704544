import {
    BALANCE_FAIL,
    BALANCE_LOAD,
    BALANCE_SUCCESS,
    CREATE_FAIL,
    CREATE_LOAD,
    CREATE_SUCCESS,
    USER_FAIL,
    USER_LOAD,
    USER_SUCCESS,
    UPDATE_WALLET_LOAD,
    UPDATE_WALLET_SUCCESS,
    UPDATE_WALLET_FAIL,
    SET_PROFILE_LOAD,
    SET_PROFILE_SUCCESS,
    SET_PROFILE_FAIL,
    SET_NAME_LOAD,
    SET_NAME_SUCCESS,
    SET_NAME_FAIL,
} from './constants'
import { userApi } from 'api/user'
import { toast } from 'react-toastify'

export const getUser = (initData, telegram_id) => (dispatch) => {
    dispatch({ type: USER_LOAD })

    userApi
        .getUser(initData, telegram_id)
        .then((response) =>
            dispatch({
                type: USER_SUCCESS,
                payload: response.data,
            })
        )
        .catch(() => {
            dispatch({ type: USER_FAIL })
        })
}

export const getBalance = (accountId) => (dispatch) => {
    dispatch({ type: BALANCE_LOAD })

    userApi
        .getBalance(accountId)
        .then((response) => {
            if (response.data && response.data.balance !== undefined) {
                dispatch({
                    type: BALANCE_SUCCESS,
                    payload: response.data.balance,
                })
            } else {
                dispatch({
                    type: BALANCE_SUCCESS,
                    payload: 0,
                })
            }
        })
        .catch((error) => {
            if (error.response && error.response.status === 404) {
                dispatch({
                    type: BALANCE_SUCCESS,
                    payload: 0,
                })
            } else {
                dispatch({ type: BALANCE_FAIL })
            }
        })
}

export const createUser = (initData, userData) => (dispatch) => {
    dispatch({ type: CREATE_LOAD })

    userApi
        .createUser(initData, userData)
        .then((response) =>
            dispatch({
                type: CREATE_SUCCESS,
                payload: response.data,
            })
        )
        .catch(() => {
            dispatch({ type: CREATE_FAIL })
        })
}

export const updateWalletAddress =
    (initData, telegram_id, wallet_address) => (dispatch) => {
        dispatch({ type: UPDATE_WALLET_LOAD })

        userApi
            .updateWalletAddress(initData, telegram_id, wallet_address)
            .then((response) => {
                dispatch({
                    type: UPDATE_WALLET_SUCCESS,
                    payload: response.data,
                })
            })
            .catch(() => {
                dispatch({ type: UPDATE_WALLET_FAIL })
            })
    }

export const setProfile = (initData, telegram_id, profile) => (dispatch) => {
    dispatch({ type: SET_PROFILE_LOAD })

    userApi
        .setProfile(initData, telegram_id, profile)
        .then((response) => {
            dispatch({
                type: SET_PROFILE_SUCCESS,
                payload: response.data,
            })
            toast.success('Link updated successfully!')
        })
        .catch(() => {
            dispatch({ type: SET_PROFILE_FAIL })
        })
}

export const setName = (initData, telegram_id, name) => (dispatch) => {
    dispatch({ type: SET_NAME_LOAD })

    userApi
        .setName(initData, telegram_id, name)
        .then((response) => {
            dispatch({
                type: SET_NAME_SUCCESS,
                payload: response.data,
            })
            toast.success('Name updated successfully!')
        })
        .catch(() => {
            dispatch({ type: SET_NAME_FAIL })
        })
}
