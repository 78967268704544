import { FAIL, LOAD, SUCCESS } from './constants'
import { invitersApi } from 'api/inviters'

export const getInviters = (filter) => (dispatch) => {
    dispatch({ type: LOAD })

    invitersApi
        .getInviters(filter)
        .then((response) =>
            dispatch({
                type: SUCCESS,
                payload: response.data,
            })
        )
        .catch(() => {
            dispatch({ type: FAIL })
        })
}
