import { createSelector } from 'reselect'
import { moduleName } from './constants'

export const stateSelector = (state) => state[moduleName]

export const name = createSelector(stateSelector, ({ name }) => name)

export const props = createSelector(stateSelector, ({ props }) => props)

export const isOpenedSelector = createSelector(
    stateSelector,
    ({ isOpened }) => isOpened
)
