import { useSelector } from 'react-redux'
import { isLoadingUserSelector } from 'models/user/selectors'
import Skeleton from 'react-loading-skeleton'
import { formatNumbers } from 'core/helpers/format-numbers'

import c from './assets/sass/counter.module.scss'

export const Counter = ({ amount, bonuses }) => {
    const isLoading = useSelector(isLoadingUserSelector)

    if (isLoading) {
        return (
            <div className={c.component}>
                <span className={c.counter}>
                    <Skeleton baseColor="#2b2929" />
                </span>
                <span className={c.bonus}>
                    <Skeleton baseColor="#2b2929" />
                </span>
            </div>
        )
    }

    const getAmount = () => {
        if (amount) {
            return `${amount} Friends`
        }

        return 'No Friends'
    }

    return (
        <div className={c.component}>
            <span className={c.counter}>{getAmount()}</span>
            <span className={c.bonus}>
                Your bonus +{bonuses ? formatNumbers(bonuses) : 0}
            </span>
        </div>
    )
}
